import React, { useContext, useLayoutEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { Renderer, Program, Texture, Mesh, Vec2, Flowmap, Triangle } from "ogl"

import Layout from "../components/layout/index.js"
import SEO from "../components/seo"
import { MainContainer, Container, Row } from "../elements/Container/index.js"
import {
  Title,
  Text,
  GreenText,
  SectionTitle,
  Button,
} from "../elements/Text/index.js"
import Cadre from "../components/Cadre"
import MediumArticles from "../components/MediumArticles"
import { family, size } from "../elements/font"
import colors from "../elements/color"
import {
  MobileMediumArticles,
  NumbersContainer,
  Number,
  Explanation,
  MoreInfos,
} from "../components/numbers"
import ImgParallax from "../components/ImgParallax"
import BackgroundBlob from "../components/Blob"
import AppContext from "../components/Context"
import GreenImage from "../components/GreenImage.js"

const ForChangeMainContainer = styled(MainContainer)`
  color: #4a4a4a;
  @media only screen and (max-width: 800px) {
    padding: 9vh 0 0 0;
  }
`

const CadreContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  padding: 0 10vw;
  @media only screen and (max-width: 800px) {
    padding: 0 6vw;
    height: auto;
  }
`

const GreenTitle = styled(Title)`
  letter-spacing: 0;
`

const ForChangeText = styled(Text)`
  width: 60%;
  margin-bottom: 5vh;
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`

const GreenDivider = styled.p`
  background: rgb(119, 173, 123);
  background: linear-gradient(
    -90deg,
    rgba(119, 173, 123, 1) 35%,
    rgba(208, 213, 162, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: ${family.Archivo};
  width: 100%;
  font-size: ${size.SSM};
  margin-top: 30vh;
  margin-bottom: 20vh;
  vertical-align: middle;
  text-align: center;
  @media only screen and (max-width: 800px) {
    width: 85%;
    font-size: 15px;
    margin: 80px auto;
  }
`

const GreenSectionTitle = styled(SectionTitle)`
  position: static;
  margin-left: 4vw;
  background: rgb(119, 173, 123);
  background: linear-gradient(
    90deg,
    rgba(119, 173, 123, 1) 35%,
    rgba(208, 213, 162, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const Alata = styled.span`
  font-family: ${family.Alata};
  font-size: 110%;
  ${props => props.bold && "font-weight: 700;"}
`

const EnvironnmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
  position: relative;
  @media only screen and (max-width: 800px) {
    width: 100%;
    flex-direction: column;
    padding: 10vh 6vw;
  }
`

const TextContainer = styled.div`
  height: 50vh;
  width: 27%;
  display: flex;
  position: relative;
  &:nth-child(3) {
    justify-content: flex-end;
    align-items: flex-end;
    margin-left: 50px;
  }
  & > p {
    margin: 0;
    text-align: justify;
    line-height: 1.2rem;
    z-index: 100;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 40vh;
    &:nth-child(3) {
      justify-content: flex-end;
      align-items: flex-start;
      margin-left: 0;
    }
    &:nth-child(4) {
      justify-content: flex-start;
      align-items: flex-end;
    }
    & > p {
      width: 80%;
    }
  }

  @media screen and (max-width: 900px) and (max-height: 430px) {
    width: 35%;

    & > p {
      margin-left: 28px;
    }
  }

  @media only screen and (min-width: 1000px) and (max-width: 1366px) {
    width: 35%;
  }
`

const ImgContainer = styled.div`
  position: absolute;
  top: ${props => (props.right ? "90%" : "20%")};
  left: ${props => (props.right ? "80%" : "-60%")};
  width: 80%;
  z-index: 50;
  @media only screen and (max-width: 600px) {
    width: 55%;
    top: ${props => (props.right ? "0" : "50%")};
    left: ${props => (props.right ? "48%" : "-3%")};
  }
  @media only screen and (max-width: 800px) {
    width: 40%;
    top: ${props => (props.right ? "0" : "50%")};
    left: ${props => (props.right ? "48%" : "-3%")};
  }
`

const ClientContainer = styled(Container)`
  margin-top: 45vh;
  @media only screen and (max-width: 800px) {
    margin-top: 0;
  }
`

const InfosContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${props => props.main && "padding: 0 2vw 0 4vw; margin-top: 7vh;"}
  @media only screen and (max-width: 800px) {
    padding: 0;
    margin: 0;
  }
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.marginLeft && "margin-left: 3vw;"}
`

const AssetsContainer = styled(ColumnContainer)`
  width: 20%;
  @media only screen and (max-width: 800px) {
    width: 50%;
  }
`

const ImagesContainer = styled.div`
  overflow: hidden;
  width: 100%;
  border: 2px solid #4a4a4a;
  border-radius: 5px;
  position: relative;
  .canvas__placeholder {
    position: absolute;
  }
  @media only screen and (max-width: 800px) {
    height: 32vh;
  }
`

const ButtonBlack = styled(Button)`
  color: #4a4a4a;
  border: solid 2px #4a4a4a;
  width: 80%;
  margin: 2vh auto;
  font-size: ${size.XXXS};
  padding: 0 10px;
  transition: 0.3s ease;
  &:hover {
    color: white;
    background: #4a4a4a;
  }
  @media only screen and (max-width: 800px) {
    font-size: 7px;
    line-height: 18px;
    margin: 0;
    width: 100%;
    margin-top: 10px;
  }
`

const ClientTitle = styled.h4`
  font-size: ${size.SM};
  font-family: ${family.Archivo};
  font-weight: normal;
  margin-bottom: 4vh;
  @media only screen and (max-width: 800px) {
    font-size: 15px;
    margin-bottom: 2vh;
  }
`

const Label = styled.p`
  font-family: ${family.Archivo};
  white-space: nowrap;
  margin-right: 10px;
  font-size: ${size.XS};
`

const LabelText = styled(Text)`
  margin: 0;
  ${props => props.center && "text-align: center; padding-left: 2vw;"}
`

const Quote = styled.p`
  position: relative;
  text-align: justify;
  padding: 35px 0px 0px 20px;
  font-size: ${size.XXS};
  font-family: ${family.Alata};
  line-height: 1rem;
  margin-bottom: 2vh;
  &::before {
    position: absolute;
    content: "“";
    left: -5px;
    top: 0;
    font-size: 50px;
    font-family: ${family.Archivo};
    padding-top: 30px;
    background: rgb(119, 173, 123);
    background: linear-gradient(
      -90deg,
      rgba(119, 173, 123, 1) 35%,
      rgba(208, 213, 162, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &::after {
    position: absolute;
    content: "”";
    right: -5px;
    bottom: -15px;
    font-size: 50px;
    font-family: ${family.Archivo};
    padding-top: 30px;
    background: rgb(119, 173, 123);
    background: linear-gradient(
      -90deg,
      rgba(119, 173, 123, 1) 35%,
      rgba(208, 213, 162, 1) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

const QuoteRighter = styled.p`
  font-size: ${size.XS};
  line-height: 15px;
  font-family: ${family.Alata};
  color: black;
  margin: 0;
  width: 100%;
  text-align: center;
`

const Post = styled.p`
  font-size: ${size.XXXS};
  font-family: ${family.Alata};
  margin: 0;
  width: 100%;
  text-align: center;
`

const GreenPoint = styled.p`
  font-family: ${family.Archivo};
  font-size: ${size.XS};
  background: rgb(119, 173, 123);
  background: linear-gradient(
    -90deg,
    rgba(119, 173, 123, 1) 35%,
    rgba(208, 213, 162, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  padding-left: 40px;
  margin-left: 2vw;
  margin-bottom: 4vh;
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background: rgba(208, 213, 162, 1);
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  @media only screen and (max-width: 800px) {
    font-size: 10px;
    line-height: 12px;
    padding-left: 25px;
    &::after {
      width: 15px;
      height: 15px;
    }
  }
`

const MobileGreenPoint = styled.div`
  height: 95%;
  padding-top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

const HandContainer = styled.div`
  width: 17%;
  margin-left: 2vw;
  ${props => props.reverse && "transform: scaleX(-1);"}
`

const ArticleContainer = styled.div`
  width: 70%;
  margin: 0 auto;
`

const GreenCadre = styled.div`
  width: 90%;
  position: relative;
  box-shadow: -2px -4px 20px rgba(119, 173, 123, 0.15),
    2px 4px 20px rgba(119, 173, 123, 0.15);
  margin: 20vh auto;
  padding: 2vh 0;
  background: white;
  @media only screen and (max-width: 800px) {
    margin: 10vh auto;
    padding: 0;
  }
`

const CadreRow = styled(Row)`
  margin: 0 auto;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

const CardTitle = styled.h4`
  font-family: ${family.Alata};
  font-size: ${size.M};
  font-weight: normal;
  padding-top: 2vh;
  margin-bottom: 4vh;
  width: 100%;
  text-align: center;
  @media only screen and (max-width: 800px) {
    font-size: 14px;
    margin-bottom: 4vh;
  }
`

const CardText = styled(Text)`
  width: 25%;
  text-align: center;
  line-height: 1.2rem;
  margin-top: 0;
  margin-left: 0;
  @media only screen and (max-width: 800px) {
    width: 100%;
    padding: 0 10px;
  }
`
const LinkBack = styled.a`
  ${props =>
    props.little
      ? `font-size: ${size.XXS}; padding: 0px 20px;`
      : `font-size: ${size.XS}; padding: 2px 50px;`}
  color: ${colors.darkBlue};
  border: solid 2px ${colors.darkBlue};
  border-radius: 5px;
  font-family: ${family.Alata};
  letter-spacing: 0.1em;
  position: relative;
  background: ${props => (props.background ? props.background : "white")};
  margin-top: 40px;
  z-index: 50;
  transition: 0.3s ease;
  text-decoration: none;
  &:hover {
    color: white;
    background: ${colors.darkBlue};
  }
  @media only screen and (max-width: 800px) {
    margin-top: 0;
    background: ${props =>
      props.noBackground ? "transparent" : props.background};
  }
`

const GreenButton = styled(LinkBack)`
  border: 2px solid #77ad7b;
  color: #77ad7b;
  padding: 10px 15px;
  height: fit-content;
  align-self: flex-end;
  margin-bottom: 4vh;
  transition: 0.3s ease;
  &:hover {
    color: white;
    background: #77ad7b;
  }
  @media only screen and (max-width: 800px) {
    margin: 0 auto;
    margin-bottom: 4vh;
  }
`

const LogosContainers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 4vh;
`

const LogoContainer = styled.div`
  width: 30%;
`

const MobileNumberContainer = styled(NumbersContainer)`
  &:not(:first-of-type) {
    width: 50% !important;
    &:nth-child(2),
    &:nth-child(3) {
      margin-bottom: 20px;
      margin-top: 70px;
    }
  }
`

const GreenNumber = styled(Number)`
  background: rgb(119, 173, 123);
  background: linear-gradient(
    -90deg,
    rgba(119, 173, 123, 1) 35%,
    rgba(208, 213, 162, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  ${props => props.little && `font-size: 30px; line-height: 30px;`}
  ${props => props.big && `font-size: 40px; line-height: 40px;`}
`

const BlackText = styled(Explanation)`
  color: #4a4a4a;
  width: 100%;
  line-height: 20px;
  position: relative;
`

const ImageGreenContainer = styled.span`
    width: ${props => (props.index === 4 ? "40px" : "60px")};
    ${props => props.index === 3 && "margin-top: 5px;"}
    /* position: absolute; */
    display: inline-block;
    height: ${props => (props.index === 3 ? "10px" : "20px")};
    ${props => props.index === 4 && "right: 22%;"}
    ${props => props.index === 3 && "right: 20%;"}
    ${props => props.index === 0 && "right: 18%;"}
    @media only screen and (max-width: 375px) {
      ${props => props.index === 4 && "right: 20%;"}
      ${props => props.index === 3 && "right: 5%;"}
      ${props => props.index === 0 && "right: 16%;"}
    }
    @media only screen and (max-width: 325px) {
      ${props => props.index === 4 && "left: 50%;"}
      ${props => props.index === 3 && "left: 57%;"}
      ${props => props.index === 0 && "left: 50%;"}
    }

    & > * {
      width: 100%;
      height: ${props => (props.index === 3 ? "10px" : "20px")} !important;
    }
`

const GreenMoreInfos = styled(MoreInfos)`
  @media only screen and (max-width: 900px) {
    top: 160%;
    width: 130px;
    height: 130px;
    padding: 0 5px;
  }
`

const Transparent = styled.span`
  opacity: 0;
`

const vertex = /* glsl */ `
  attribute vec2 uv;
  attribute vec2 position;
  varying vec2 vUv;
  void main() {
    vUv = uv;
    gl_Position = vec4(position, 0, 1);
  }`

const fragment = /* glsl */ `
    precision highp float;
    precision highp int;
    uniform sampler2D tWater;
    uniform sampler2D tFlow;
    uniform float uTime;
    uniform float uWidth;
    uniform float uHeight;
    varying vec2 vUv;
    void main() {
      // R and G values are velocity in the x and y direction
      // B value is the velocity length
      vec3 flow = texture2D(tFlow, vUv).rgb;
      // Use flow to adjust the uv lookup of a texture
      vec2 uv = vec2(gl_FragCoord.x / uWidth, gl_FragCoord.y / uHeight);
      uv += flow.xy * 0.05;
      vec3 tex = texture2D(tWater, uv).rgb;
      // Oscillate between raw values and the affected texture above
      gl_FragColor = vec4(tex.rgb, 1.0);
    }
  `

const PageNovliniForChange = ({ data, path }) => {
  const { isMobile: mobile, isTablet } = useContext(AppContext)
  const prismic = data.prismicNovliniforchange.data

  const isMobile = mobile || isTablet

  useLayoutEffect(() => {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
        navigator.userAgent
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw(n|u)|c55\/|capi|ccwa|cdm|cell|chtm|cldc|cmd|co(mp|nd)|craw|da(it|ll|ng)|dbte|dcs|devi|dica|dmob|do(c|p)o|ds(12|d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(|_)|g1 u|g560|gene|gf5|gmo|go(\.w|od)|gr(ad|un)|haie|hcit|hd(m|p|t)|hei|hi(pt|ta)|hp( i|ip)|hsc|ht(c(| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i(20|go|ma)|i230|iac( ||\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|[a-w])|libw|lynx|m1w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|mcr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|([1-8]|c))|phil|pire|pl(ay|uc)|pn2|po(ck|rt|se)|prox|psio|ptg|qaa|qc(07|12|21|32|60|[2-7]|i)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h|oo|p)|sdk\/|se(c(|0|1)|47|mc|nd|ri)|sgh|shar|sie(|m)|sk0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h|v|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl|tdg|tel(i|m)|tim|tmo|to(pl|sh)|ts(70|m|m3|m5)|tx9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas|your|zeto|zte/i.test(
        navigator.userAgent.substr(0, 4)
      )
    ) {
    } else if (!isMobile && document.querySelector(".gettexture > picture")) {
      const renderer = new Renderer({ dpr: 1 })
      const gl = renderer.gl
      const placeholder = document.querySelector(".canvas__placeholder")
      const getImgDOM = document.querySelector(".gettexture")
      const getTexture = getImgDOM.querySelector("picture > img")
      placeholder.appendChild(gl.canvas)
      getImgDOM.style.visibility = "hidden"

      let aspect = 1
      const mouse = new Vec2(-1)
      const velocity = new Vec2()

      function resize() {
        renderer.setSize(
          getImgDOM.getBoundingClientRect().width,
          getImgDOM.getBoundingClientRect().height
        )
        aspect =
          getImgDOM.getBoundingClientRect().width /
          getImgDOM.getBoundingClientRect().height
      }
      window.addEventListener("resize", resize, false)
      resize()

      const flowmap = new Flowmap(gl, {
        falloff:
          navigator.userAgent.toLowerCase().indexOf("safari/") > -1 ? 0.3 : 0.2,
        dissipation: 0.8,
      })

      // Triangle that includes -1 to 1 range for 'position', and 0 to 1 range for 'uv'.
      const geometry = new Triangle(gl)

      const texture = new Texture(gl, {
        minFilter: gl.LINEAR,
        magFilter: gl.LINEAR,
        wrapS: gl.CLAMP_TO_BORDER,
        wrapT: gl.CLAMP_TO_BORDER,
      })
      const img = new Image()
      img.onload = () => (texture.image = img)
      if (getTexture !== null) {
        img.src = getTexture.src
      }

      const program = new Program(gl, {
        vertex,
        fragment,
        uniforms: {
          uTime: { value: 0 },
          uWidth: { value: 225 },
          uHeight: { value: 340 },
          tWater: { value: texture },
          tFlow: flowmap.uniform,
        },
      })

      const mesh = new Mesh(gl, { geometry, program })

      // Create handlers to get mouse position and velocity
      const isTouchCapable = "ontouchstart" in window
      if (isTouchCapable) {
        window.addEventListener("touchstart", updateMouse, false)
        window.addEventListener("touchmove", updateMouse, false)
      } else {
        window.addEventListener("mousemove", updateMouse, false)
      }

      let lastTime
      const lastMouse = new Vec2()
      function updateMouse(e) {
        if (e.changedTouches && e.changedTouches.length) {
          e.x = e.changedTouches[0].pageX
          e.y = e.changedTouches[0].pageY
        }
        if (e.x === undefined) {
          e.x = e.clientX
          e.y = e.clientY
        }

        mouse.set(
          (e.x - getImgDOM.getBoundingClientRect().left) / gl.renderer.width,
          1 - (e.y - getImgDOM.getBoundingClientRect().top) / gl.renderer.height
        )
        // Calculate velocity
        if (!lastTime) {
          // First frame
          lastTime = performance.now()
          lastMouse.set(e.x, e.y)
        }

        const deltaX = e.x - lastMouse.x
        const deltaY = e.y - lastMouse.y

        lastMouse.set(e.x, e.y)

        let time = performance.now()

        // Avoid dividing by 0
        let delta = Math.max(14, time - lastTime)
        lastTime = time

        velocity.x = deltaX / delta
        velocity.y = deltaY / delta

        // Flag update to prevent hanging velocity values when not moving
        velocity.needsUpdate = true
      }

      requestAnimationFrame(update)
      function update(t) {
        requestAnimationFrame(update)

        // Reset velocity when mouse not moving
        if (!velocity.needsUpdate) {
          mouse.set(-1)
          velocity.set(0)
        }
        velocity.needsUpdate = false

        // Update flowmap inputs
        flowmap.aspect = aspect
        flowmap.mouse.copy(mouse)

        // Ease velocity input, slower when fading out
        flowmap.velocity.lerp(velocity, velocity.len ? 0.5 : 0.1)

        flowmap.update()
        program.uniforms.uTime.value = t * 0.0005
        program.uniforms.uWidth.value = renderer.width
        program.uniforms.uHeight.value = renderer.height

        renderer.render({ scene: mesh })
      }
    }
  }, [fragment, vertex, isMobile])
  return (
    <Layout
      pageValueMenu={3}
      blackLogo={false}
      headerString={["#novliniforchange", "Greta-compatible", "Cas client", ""]}
      hasScrolled={true}
      footerText={prismic.footer_quote.text}
      green={true}
    >
      <SEO
        title={prismic.page_title.text}
        description={prismic.page_description.text}
        image={prismic.og_image.url}
        location={path}
      />
      <ForChangeMainContainer>
        {isMobile ? (
          <>
            <svg
              className="bulle-mobile-droite-soft"
              style={{ position: "absolute", right: "0", top: "5%" }}
              width="344"
              height="668"
              viewBox="0 0 344 668"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M245.896 1.07582C296.88 -3.06819 354.077 3.27806 390.664 46.5487C426.134 88.4979 409.868 160.565 428.026 216.475C448.119 278.34 511.945 323.806 501.944 389.184C492.039 453.933 422.807 472.143 382.225 515.885C335.313 566.451 307.721 652.114 245.896 665.173C182.351 678.595 110.139 643.141 70.0787 581.817C31.9875 523.508 62.7779 438.228 50.0906 365.439C39.4425 304.349 -10.9648 252.57 2.16679 192.195C15.3726 131.48 71.0968 103.079 114.569 68.99C155.536 36.8656 197.224 5.03194 245.896 1.07582Z"
                fill="#D0D5A2"
                fillOpacity="0.2"
              />
            </svg>
            <svg
              className="bulle-mobile-gauche-soft"
              style={{ position: "absolute", left: "-5vw", top: "128vh" }}
              width="305"
              height="668"
              viewBox="0 0 295 668"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M37.8962 1.07582C88.8799 -3.06819 146.077 3.27806 182.664 46.5487C218.134 88.4979 201.868 160.565 220.026 216.475C240.119 278.34 303.945 323.806 293.944 389.184C284.039 453.933 214.807 472.143 174.225 515.885C127.313 566.451 99.7215 652.114 37.8962 665.173C-25.6489 678.595 -97.8605 643.141 -137.921 581.817C-176.013 523.508 -145.222 438.228 -157.909 365.439C-168.557 304.349 -218.965 252.57 -205.833 192.195C-192.627 131.48 -136.903 103.079 -93.4311 68.99C-52.4636 36.8656 -10.776 5.03194 37.8962 1.07582Z"
                fill="#D0D5A2"
                fillOpacity="0.2"
              />
            </svg>
          </>
        ) : (
          <BackgroundBlob style={{ right: "-8%" }} green />
        )}
        <Container paddingTop>
          {isMobile && (
            <GreenSectionTitle>
              01.
              <br />
              {prismic.section_title_mobile.text}
            </GreenSectionTitle>
          )}
          <GreenTitle>
            <GreenText>{prismic.green_title.text}</GreenText>
            {isMobile && <br />} {prismic.title_part_black.text}{" "}
            {isMobile && <br />}
            <GreenText>{prismic.green_title_1.text}</GreenText>
          </GreenTitle>
          <ForChangeText>
            {prismic.subtitle_text.text.includes("novlini")
              ? prismic.subtitle_text.text.split("novlini").map((item, i) => (
                  <span key={i}>
                    {item}
                    {i === 0 && <Alata>novlini</Alata>}
                  </span>
                ))
              : prismic.subtitle_text.text}
          </ForChangeText>
        </Container>
        <CadreContainer>
          <Cadre
            green={true}
            id="cadre-novliniforchange"
            text1={prismic.cadre_title.text}
            text2={{
              titre: prismic.cadre_subtitle.raw,
              point1: prismic.cadre_point_1.text,
              point2: prismic.cadre_point_2.text,
              point3: prismic.cadre_point_3.text,
            }}
            location={path}
          />
        </CadreContainer>
        <Container
          className="changeHeaderString"
          style={{ marginTop: isMobile ? "2vh" : "15vh" }}
          paddingTop
        >
          {!isMobile && (
            <BackgroundBlob
              style={{ left: "-20%", width: "60%", top: "0" }}
              green
            />
          )}
          {isMobile ? (
            <>
              <GreenSectionTitle>
                02.
                <br />
                {prismic.section_title_mobile_1.text}
              </GreenSectionTitle>
              <MobileMediumArticles>
                <MobileNumberContainer>
                  <GreenNumber big>
                    {prismic.green_datas[2].chiffre_text.text}
                  </GreenNumber>
                  <BlackText>{prismic.green_datas[2].data_text.text}</BlackText>
                  <GreenMoreInfos color="#BAFF0D">
                    {prismic.green_datas[2].bubble_text.text}
                  </GreenMoreInfos>
                </MobileNumberContainer>
                <MobileNumberContainer>
                  <GreenNumber little>
                    {prismic.green_datas[0].chiffre_text.text}
                  </GreenNumber>
                  <BlackText>
                    {prismic.green_datas[0].data_text.text.match(/-\w+-/gm) ? (
                      <>
                        {prismic.green_datas[0].data_text.text
                          .split(/-\w+-/gm)
                          .map((item, i) => (
                            <span key={i}>
                              {item}
                              {i === 0 && <Transparent></Transparent>}
                            </span>
                          ))}
                      </>
                    ) : (
                      prismic.green_datas[0].data_text.text
                    )}{" "}
                    <ImageGreenContainer index={0}>
                      <GreenImage index={0} />
                    </ImageGreenContainer>
                  </BlackText>
                </MobileNumberContainer>
                <MobileNumberContainer>
                  <GreenNumber little>
                    {prismic.green_datas[1].chiffre_text.text}
                  </GreenNumber>
                  <BlackText>{prismic.green_datas[1].data_text.text}</BlackText>
                </MobileNumberContainer>
                <MobileNumberContainer>
                  <GreenNumber little>
                    {prismic.green_datas[4].chiffre_text.text}
                  </GreenNumber>
                  <BlackText>
                    {prismic.green_datas[4].data_text.text.match(/-\w+-/gm) ? (
                      <>
                        {prismic.green_datas[4].data_text.text
                          .split(/-\w+-/gm)
                          .map((item, i) => (
                            <span key={i}>
                              {item}
                              {i === 0 && <Transparent></Transparent>}
                            </span>
                          ))}
                      </>
                    ) : (
                      prismic.green_datas[4].data_text.text
                    )}{" "}
                    <ImageGreenContainer index={4}>
                      <GreenImage index={4} />
                    </ImageGreenContainer>
                  </BlackText>
                </MobileNumberContainer>
                <MobileNumberContainer>
                  <GreenNumber little>
                    {prismic.green_datas[3].chiffre_text.text}
                  </GreenNumber>
                  <BlackText>
                    {prismic.green_datas[3].data_text.text.match(/-\w+-/gm) ? (
                      <>
                        {prismic.green_datas[3].data_text.text
                          .split(/-\w+-/gm)
                          .map((item, i) => (
                            <span key={i}>
                              {item}
                              {i === 0 && <Transparent></Transparent>}
                            </span>
                          ))}
                      </>
                    ) : (
                      prismic.green_datas[3].data_text.text
                    )}{" "}
                    <ImageGreenContainer index={3}>
                      <GreenImage index={3} />
                    </ImageGreenContainer>
                  </BlackText>
                </MobileNumberContainer>
              </MobileMediumArticles>
            </>
          ) : (
            <MediumArticles
              green={true}
              greenImages={true}
              stretch={true}
              articles={prismic.green_datas.map(data => {
                return {
                  hoverText: data.bubble_text.text,
                  greenData: data.chiffre_text.text,
                  title: data.data_text.text,
                }
              })}
              circleColor="#FFE8DB"
            />
          )}
        </Container>
        <GreenDivider className="changeHeaderString">
          {prismic.quote.text}
        </GreenDivider>
        <EnvironnmentContainer>
          {!isMobile && (
            <BackgroundBlob style={{ right: "0", width: "70%" }} green />
          )}
          {isMobile && (
            <GreenSectionTitle style={{ marginLeft: 0 }}>
              03.
              <br />
              {prismic.section_title_mobile_2.text}
            </GreenSectionTitle>
          )}
          {isMobile && (
            <svg
              className="bulle-mobile-droite-soft"
              style={{ position: "absolute", right: "-5vw", top: "10%" }}
              width="344"
              height="668"
              viewBox="0 0 344 668"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M245.896 1.07582C296.88 -3.06819 354.077 3.27806 390.664 46.5487C426.134 88.4979 409.868 160.565 428.026 216.475C448.119 278.34 511.945 323.806 501.944 389.184C492.039 453.933 422.807 472.143 382.225 515.885C335.313 566.451 307.721 652.114 245.896 665.173C182.351 678.595 110.139 643.141 70.0787 581.817C31.9875 523.508 62.7779 438.228 50.0906 365.439C39.4425 304.349 -10.9648 252.57 2.16679 192.195C15.3726 131.48 71.0968 103.079 114.569 68.99C155.536 36.8656 197.224 5.03194 245.896 1.07582Z"
                fill="#D0D5A2"
                fillOpacity="0.2"
              />
            </svg>
          )}
          <TextContainer>
            <ImgContainer>
              <ImgParallax speed={3.9}>
                <Img
                  loading="eager"
                  fluid={prismic.image_1.localFile.childImageSharp.fluid}
                />
              </ImgParallax>
            </ImgContainer>
            <Text>
              {prismic.image_1_text.text.includes("novlini")
                ? prismic.image_1_text.text.split("novlini").map((item, i) => (
                    <span key={i}>
                      {item}
                      {i === 0 && <Alata>novlini</Alata>}
                    </span>
                  ))
                : prismic.image_1_text.text}
            </Text>
          </TextContainer>
          <TextContainer>
            <ImgContainer right>
              <ImgParallax speed={2.9}>
                <Img
                  loading="eager"
                  fluid={prismic.image_2.localFile.childImageSharp.fluid}
                />
              </ImgParallax>
            </ImgContainer>
            <Text className="changeHeaderString">
              {prismic.image_2_text.text}
            </Text>
          </TextContainer>
        </EnvironnmentContainer>
        <ClientContainer paddingTop>
          {isMobile && (
            <GreenSectionTitle>
              04.
              <br />
              {prismic.section_title_mobile_3.text}
            </GreenSectionTitle>
          )}
          <GreenTitle>
            <GreenText>{prismic.title_part_green.text}</GreenText>
            {isMobile && <br />} {prismic.title_client_black.text}{" "}
            {isMobile && <br />}
            <GreenText>{prismic.title_part_green_1.text}</GreenText>
          </GreenTitle>
          {isMobile && (
            <ClientTitle style={{ marginLeft: "4vw" }}>
              {prismic.client_title.text}
            </ClientTitle>
          )}
          <InfosContainer main>
            <AssetsContainer>
              <ImagesContainer>
                {isMobile ? (
                  <>
                    <Img
                      loading="eager"
                      fluid={
                        prismic.client_image.localFile.childImageSharp.fluid
                      }
                    />
                    <Img
                      loading="eager"
                      fluid={
                        prismic.client_logo.localFile.childImageSharp.fluid
                      }
                    />
                  </>
                ) : (
                  <>
                    <div className="canvas__placeholder"></div>
                    <Img
                      loading="eager"
                      className="gettexture"
                      fluid={
                        prismic.client_image.localFile.childImageSharp.fluid
                      }
                    />
                    <Img
                      loading="eager"
                      fluid={
                        prismic.client_logo.localFile.childImageSharp.fluid
                      }
                    />
                  </>
                )}
              </ImagesContainer>
              <ButtonBlack className="click" background="#FDF3F0">
                <a
                  href={prismic.discover_link.url}
                  title="Découvrir"
                  style={{ textDecoration: "none", color: "inherit" }}
                  target={prismic.discover_link.target}
                >
                  découvrir pimpant
                </a>
              </ButtonBlack>
            </AssetsContainer>
            <ColumnContainer marginLeft style={{ width: "80%" }}>
              {isMobile ? (
                <MobileGreenPoint>
                  {prismic.green_points.map((point, index) => (
                    <GreenPoint key={index}>
                      {point.green_point.text}
                    </GreenPoint>
                  ))}
                </MobileGreenPoint>
              ) : (
                <>
                  <ColumnContainer
                    style={{ width: "80%", marginBottom: "5vh" }}
                  >
                    <ClientTitle>{prismic.client_title.text}</ClientTitle>
                    <InfosContainer>
                      <Label>Engagement :</Label>
                      <LabelText>{prismic.client_subtitle.text}</LabelText>
                    </InfosContainer>
                  </ColumnContainer>
                  <InfosContainer>
                    <ColumnContainer style={{ width: "55%" }}>
                      {prismic.green_points.map((point, index) => (
                        <GreenPoint key={index}>
                          {point.green_point.text}
                        </GreenPoint>
                      ))}
                      <InfosContainer
                        style={{ alignItems: "center", marginTop: "3vh" }}
                      >
                        <HandContainer>
                          <Img
                            loading="eager"
                            fluid={data.hand.childImageSharp.fluid}
                          />
                        </HandContainer>
                        <LabelText center>
                          {prismic.client_infos.text}
                        </LabelText>
                        <HandContainer reverse>
                          <Img
                            loading="eager"
                            fluid={data.hand.childImageSharp.fluid}
                          />
                        </HandContainer>
                      </InfosContainer>
                      <LogosContainers>
                        {prismic.green_logos.map((logo, index) => (
                          <LogoContainer key={index}>
                            <Img
                              loading="eager"
                              fluid={
                                logo.green_logo.localFile.childImageSharp.fluid
                              }
                            />
                          </LogoContainer>
                        ))}
                      </LogosContainers>
                    </ColumnContainer>
                    <ColumnContainer style={{ width: "40%" }}>
                      <Quote>
                        {prismic.client_quote.text.includes("novlini")
                          ? prismic.client_quote.text
                              .split("novlini")
                              .map((item, i) => (
                                <span key={i}>
                                  {item}
                                  {i === 0 && <Alata bold>novlini</Alata>}
                                </span>
                              ))
                          : prismic.client_quote.text}
                      </Quote>
                      <QuoteRighter>{prismic.author_quote.text}</QuoteRighter>
                      <Post>{prismic.author_post.text}</Post>
                      <ArticleContainer>
                        <MediumArticles
                          single={true}
                          right={true}
                          articles={[
                            {
                              title: prismic.article_title.text,
                              link: prismic.article_medium_link.url,
                            },
                          ]}
                          circleColor="#BAFF0D"
                        />
                      </ArticleContainer>
                    </ColumnContainer>
                  </InfosContainer>
                </>
              )}
            </ColumnContainer>
          </InfosContainer>
          {isMobile && (
            <ColumnContainer style={{ width: "100%" }}>
              <LogosContainers>
                {prismic.green_logos.map((logo, index) => (
                  <LogoContainer key={index}>
                    <Img
                      loading="eager"
                      fluid={logo.green_logo.localFile.childImageSharp.fluid}
                    />
                  </LogoContainer>
                ))}
              </LogosContainers>
              <Quote>
                {prismic.client_quote.text.includes("novlini")
                  ? prismic.client_quote.text
                      .split("novlini")
                      .map((item, i) => (
                        <span key={i}>
                          {item}
                          {i === 0 && <Alata bold>novlini</Alata>}
                        </span>
                      ))
                  : prismic.client_quote.text}
              </Quote>
              <QuoteRighter>{prismic.author_quote.text}</QuoteRighter>
              <Post>{prismic.author_post.text}</Post>
              <ArticleContainer>
                <MediumArticles
                  className="single"
                  right={true}
                  articles={[
                    {
                      title: prismic.article_title.text,
                      link: prismic.article_medium_link.url,
                    },
                  ]}
                  circleColor="#BAFF0D"
                />
              </ArticleContainer>
              <svg
                className="bulle-mobile-gauche-soft"
                style={{
                  position: "absolute",
                  left: "0",
                  top: "40%",
                  zIndex: "0",
                  width: "70%",
                }}
                width="295"
                height="668"
                viewBox="0 0 295 668"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M37.8962 1.07582C88.8799 -3.06819 146.077 3.27806 182.664 46.5487C218.134 88.4979 201.868 160.565 220.026 216.475C240.119 278.34 303.945 323.806 293.944 389.184C284.039 453.933 214.807 472.143 174.225 515.885C127.313 566.451 99.7215 652.114 37.8962 665.173C-25.6489 678.595 -97.8605 643.141 -137.921 581.817C-176.013 523.508 -145.222 438.228 -157.909 365.439C-168.557 304.349 -218.965 252.57 -205.833 192.195C-192.627 131.48 -136.903 103.079 -93.4311 68.99C-52.4636 36.8656 -10.776 5.03194 37.8962 1.07582Z"
                  fill="#D0D5A2"
                  fillOpacity="0.2"
                />
              </svg>
            </ColumnContainer>
          )}
          <BackgroundBlob
            style={{ left: "-20%", width: "50%", top: "40%" }}
            green
          />
        </ClientContainer>
        <GreenCadre>
          <CardTitle>{prismic.green_cadre_title.text}</CardTitle>
          <CadreRow>
            <CardText>{prismic.green_cadre_text.text}</CardText>
            {!isMobile && (
              <GreenButton
                href="https://calendly.com/novlini/rdv-equipe-novlini"
                className="click"
              >
                discutez maintenant !
              </GreenButton>
            )}
            <CardText>{prismic.green_cadre_text_1.text}</CardText>
            {isMobile && (
              <GreenButton href="https://calendly.com/novlini/rdv-equipe-novlini">
                discutez maintenant !
              </GreenButton>
            )}
          </CadreRow>
        </GreenCadre>
      </ForChangeMainContainer>
    </Layout>
  )
}

export default PageNovliniForChange

export const query = graphql`
  query {
    hand: file(relativePath: { eq: "clap.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    yuka: file(relativePath: { eq: "yuka.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    ecosia: file(relativePath: { eq: "ecosia.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    tgtg: file(relativePath: { eq: "tgtg.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 500) {
          # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    prismicNovliniforchange {
      data {
        page_title {
          text
        }
        page_description {
          text
        }
        og_image {
          url
        }
        image_2 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        article_medium_link {
          url
        }
        article_title {
          text
        }
        author_post {
          text
        }
        author_quote {
          text
        }
        cadre_point_1 {
          text
        }
        cadre_point_2 {
          text
        }
        cadre_point_3 {
          text
        }
        cadre_subtitle {
          raw
        }
        cadre_title {
          text
        }
        client_image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        client_infos {
          text
        }
        client_logo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        client_quote {
          text
        }
        client_subtitle {
          text
        }
        client_title {
          text
        }
        footer_quote {
          text
        }
        green_cadre_text {
          text
        }
        green_cadre_text_1 {
          text
        }
        green_cadre_title {
          text
        }
        green_datas {
          bubble_text {
            text
          }
          chiffre_text {
            text
          }
          data_text {
            text
          }
        }
        green_logos {
          green_logo {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        green_points {
          green_point {
            text
          }
        }
        green_title {
          text
        }
        green_title_1 {
          text
        }
        image_1 {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        image_1_text {
          text
        }
        image_2_text {
          text
        }
        quote {
          text
        }
        section_title_mobile {
          text
        }
        section_title_mobile_1 {
          text
        }
        section_title_mobile_2 {
          text
        }
        section_title_mobile_3 {
          text
        }
        subtitle_text {
          text
        }
        title_client_black {
          text
        }
        title_part_black {
          text
        }
        title_part_green {
          text
        }
        title_part_green_1 {
          text
        }
        discover_link {
          url
          target
        }
      }
    }
  }
`
